<template>
  <div>
    <div class="maintitle">用户推荐统计</div>
    <el-divider></el-divider>
    <el-row class="text-left">
      <el-select
        v-model="crntprovince"
        @change="slctprovince"
        placeholder="请选择省份"
        style="width:120px;"
      >
        <el-option
          v-for="(item,index) in province"
          :key="index"
          :label="item.fullname"
          :value="item.id"
        ></el-option>
      </el-select>
      <el-select v-model="crntcity" placeholder="请选择城市" @change="slctcity" style="width:120px;">
        <el-option
          v-for="(item,index) in city"
          :key="index"
          :label="item.fullname"
          :value="item.id"
        ></el-option>
      </el-select>
      <el-select v-model="crntdistrict" placeholder="请选择县/区" style="width:120px;">
        <el-option
          v-for="(item,index) in district"
          :key="index"
          :label="item.fullname"
          :value="item.id"
        ></el-option>
      </el-select>
      <el-date-picker
        v-model="timerange"
        type="daterange"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        :default-time="['00:00:00', '23:59:59']"
        style="width:250px;"
        class="margin-big-left"
      ></el-date-picker>
      <el-button type="primary" @click="srch('diy')" class="margin-left">搜索</el-button>
    </el-row>
    <el-table :data="list" border style="width:800px">
      <el-table-column fixed prop="nickname" label="昵称"></el-table-column>
      <el-table-column prop="tel" label="手机号" width="120"></el-table-column>
      <el-table-column prop="num" label="推荐人数" width="120"></el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [], //业务人员列表

      page: 1, //当前页数
      // pagesize: this.pagesize,//当前每页条数
      // pagesizes: this.pagesizes,//每页条数列表
      province: [],
      city: [],
      district: [],
      crntprovince: "",
      crntcity: "",
      crntdistrict: "",
      timerange: [this.getdate("start"), this.getdate("end")], //查找时间范围，默认为本月
    };
  },
  computed: {},
  methods: {
    getlist: function() {
      let that = this;
      this.$axios({
        url: this.HOST + "/admin/member/getRecommon",
        method: "post",
        data: {
          district:this.crntdistrict,
          timerange:this.timerange
        }
      }).then(res => {
        if (res.data.status == 1) {
          this.list = res.data.list;
        }
      });
    },
    handleSizeChange(size) {
      this.pagesize = size;
      this.page = 1;
      this.getlist();
    },
    handleCurrentChange(p) {
      this.page = p;
      this.getlist();
    },
    getdate(str) {
      let date = new Date();
      if (str == "start") {
        return (
          date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + "01 00:00:00"
        );
      } else {
        return (
          date.getFullYear() +
          "-" +
          (date.getMonth() + 1) +
          "-" +
          date.getDate() +
          " 23:59:59"
        );
      }
    },
    getAreaList(id, str) {
      let url = this.HOST + "/admin/index/getAreaList";
      this.$axios({
        method: "post",
        url: url,
        data: {
          id: id
        }
      }).then(res => {
        if (res.data.status == 1) {
          switch (str) {
            case "p":
              this.province = res.data.list;
              this.city = [];
              this.district = [];
              this.crntprovince = "";
              this.crntcity = "";
              this.crntdistrict = "";
              break;
            case "c":
              this.city = res.data.list;
              this.district = [];
              this.crntcity = "";
              this.crntdistrict = "";
              break;
            case "co":
              this.district = res.data.list;
              this.crntdistrict = "";
              break;
          }
        } else {
          return [];
        }
      });
    },
    slctprovince() {
      this.getAreaList(this.crntprovince, "c");
    },
    slctcity() {
      this.getAreaList(this.crntcity, "co");
    },
    srch(str) {
      this.srchtype = str=='diy'?this.timerange:str;
      this.page = 1;
      this.getlist();
    },
  },
  created: function() {
    this.getlist();
    this.getAreaList(0,'p');
  }
};
</script>

<style>
</style>